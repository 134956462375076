import React from "react"
import { graphql } from "gatsby"
// import Bio from "../components/bio"
import Layout from "../components/layout"
import Head from "../components/head"
// import { rhythm } from "../utils/typography"
import '../styles/index.scss'
import algoliasearch from 'algoliasearch/lite'
import { InstantSearch, SearchBox, Hits, Highlight } from 'react-instantsearch-dom'
import ArticlePreview from '../components/article-preview'


const searchClient = algoliasearch('D4ILCVWF60', '2de73c44b0d2bec5117b546b7129005b');



class SearchIndex extends React.Component {
  render() {

    const { data } = this.props
    const siteTitle = <h1>{data.site.siteMetadata.title}</h1>

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <Head />
        {/* <Bio /> */}
        <InstantSearch searchClient={searchClient} indexName="esignHelp">
          <SearchBox />
          <Hits hitComponent={ArticlePreview}/>
        </InstantSearch>
      </Layout>
    )
  }
}

export default SearchIndex

export const pageQuery = graphql`
query {
  site {
    siteMetadata {
      title
    }
  }
}
`




