import React from "react"
import { Link } from "gatsby"
import { rhythm } from "../utils/typography"
import { Highlight } from 'react-instantsearch-dom'

const ArticlePreview=({hit})=>{    
    
    const truncate = (input) => (input.length > 100)?`${input.substring(0, 80)}...`:input

    // for(const i=0; i<hit.content.json.content.length; i++){
        return (
        <Link to={hit.slug}>
            <div className="header">
                <div className="masthead">
                    <h3
                    style={{
                        marginBottom: rhythm(1 / 4),
                    }}
                    >
                    <span style={{ boxShadow: `none`, fontFamily: '"Verdana", "Geneva", sans-serif', color:"rgba(0,122,204,1)" }} to={hit.slug}>
                    <Highlight
                    attribute={"title"}
                    hit={hit}
                    tagName="mark"
                    />
                    </span>
                    </h3>
                    <small>{hit.createdAt}</small>
                    <p dangerouslySetInnerHTML={{
                        __html: truncate(hit.content.childContentfulRichText.html)
                    }}/>
                </div>
            </div>
        </Link>
        )
    }
// }


export default ArticlePreview






// OLD INDEX CODE
// {<Layout location={this.props.location} title={siteTitle}>
// <Head />
// {/* <Bio /> */}
// <InstantSearch searchClient={searchClient} indexName="help">
//   <SearchBox />
//   <Hits />
// </InstantSearch>
// {posts.map(({ node }) => {

//   const title = node.title || node.slug

//   const brief=JSON.stringify(node.content.json.content[0].content[0].value)


//   return (
//    <ArticlePreview 
//    key={node.slug}
//    slug={node.slug} 
//    title={title} 
//    date={node.createdAt} 
//    body={brief}/>
//   )
// })}
// </Layout>
//}




// OLD ARTICLE PREVIEW JS CODE

// import React from "react"
// import { Link } from "gatsby"
// import { rhythm } from "../utils/typography"

// const ArticlePreview=({slug, title, date, body})=>{    
//     const truncate = (input) => input.length > 5 ? `${input.substring(0, 80)}...` : input;

//     return (
//     <Link to={slug}>
//         <div className="header">
//             <div className="masthead">
//                 <h3
//                 style={{
//                     marginBottom: rhythm(1 / 4),
//                 }}
//                 >
//                 <span style={{ boxShadow: `none`, fontFamily: '"Verdana", "Geneva", sans-serif', color:"#007acc" }} to={slug}>
//                     {title}
//                 </span>
//                 </h3>
//                 <small>{date}</small>

//                 <p
//                 dangerouslySetInnerHTML={{
//                     __html: truncate(JSON.parse(body)),
//                 }}
//                 />
//             </div>
//         </div>
//     </Link>
//     )
// }

// export default ArticlePreview





